/* ---------------------- */
/* Width */
/* ---------------------- */

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-88 {
  width: 22rem;
}

.w-96 {
  width: 24rem;
}

.w-104 {
  width: 26rem;
}

.w-112 {
  width: 28rem;
}

.w-120 {
  width: 30rem;
}

.w-128 {
  width: 32rem;
}

.w-136 {
  width: 34rem;
}

.w-144 {
  width: 36rem;
}

.w-152 {
  width: 38rem;
}

.w-160 {
  width: 40rem;
}

/* ---------------------- */
/* Height */
/* ---------------------- */

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-88 {
  height: 22rem;
}

.h-96 {
  height: 24rem;
}

.h-104 {
  height: 26rem;
}

.h-112 {
  height: 28rem;
}

.h-120 {
  height: 30rem;
}

.h-128 {
  height: 32rem;
}

.h-136 {
  height: 34rem;
}

.h-144 {
  height: 34rem;
}

/* ---------------------- */
/* Min-width */
/* ---------------------- */

.min-w-64 {
  min-width: 16rem;
}

.min-w-72 {
  min-width: 18rem;
}

/* ---------------------- */
/* Min-width */
/* ---------------------- */

.min-h-112 {
  min-height: 28rem;
}

/* ---------------------- */
/* Max-height */
/* ---------------------- */


.max-h-104 {
  max-height: 26rem;
}

.max-h-112 {
  max-height: 28rem;
}

.max-h-120 {
  max-height: 30rem;
}

.max-h-128 {
  max-height: 32rem;
}

.max-h-136 {
  max-height: 34rem;
}

.max-h-144 {
  max-height: 34rem;
}